<template>
  <div class="wrap">
    <!--    <van-nav-bar-->
    <!--      title="招采公告"-->
    <!--    />-->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div v-if="!loading && list.length ==0 " class="no-data">
        <img :src="noData" alt="">
        暂无招采项目
      </div>
      <van-list
          v-else
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="(item,index) in list" :key="index" class="listItem" @click="gotoDetails(item.endInquiryId)">
          <div class="listItemTop">
            <p class="title">{{ item.engineeringName }}</p>
            <div :class="[!item.isAssignQuotedPrice?'service':'offer','rightDiv']"
                 @click.stop="openQuotedPrice(item.endInquiryId,item.isAssignQuotedPrice)">
              <p>{{ !item.isAssignQuotedPrice ? '联系客服' : '立即报价' }}</p>
              <div :class="[!item.isAssignQuotedPrice?'blue':'white','icon']"></div>
            </div>
          </div>
          <p>报价期限： {{ `${item.beginQuotedDate}-${item.cutoffQuotedDate}` }}</p>
          <div class="stoneDiv" style="display: flex">
            <span>项目石种：</span>
            <div class="stoneMain">
              <span v-for="(item2,index2) in item.itemNames" :key="index2">{{
                  item2
                }}{{ index2 != item.itemNames.length - 1 ? '·' : '' }}</span>
            </div>

          </div>
        </div>
      </van-list>

    </van-pull-refresh>
    <div class="routerToBox" @click="openNewPage">
      <p>我的报价</p>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue';
import {useRouter} from 'vue-router'
import {liH5Bulletin} from '@/api/index'

const {Session} = require("../../utils/storage");


export default {
  name: "index",
  setup() {
    const router = useRouter();
    const state = reactive({
      noData: require("@/assets/quotedPrice/none@2x.png"),
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      pageIndex: 1,
      list: [],
    })
    const openNewPage = () => {
      let url = Session.get('token') ? '/quotationList' : '/login';
      router.push(url)
    }
    // 直接跳转 不需要验证登录
    const gotoDetails = (id) => {
      router.push({
        path: '/quotationDetails',
        query: {
          endInquiryId: id,
        }
      })
    }
    //点击立即报价 or 联系客服
    const openQuotedPrice = (id, type) => {
      if (type == 1) {
        let url = '/quotationDetails';
        let obj = {
          endInquiryId: id
        }
        if (!Session.get('token')) {
          url = '/login'
          obj.url = '/quotationDetails'
        }
        router.push({
          path: url,
          query: obj
        })
      } else {
        window.location.href = "https://qiyukf.com/client?k=a346da28ce60080e619ece97a6193f54&wp=1"
      }
    }
    //触底加载
    const onLoad = () => {
      if (state.refreshing) {
        state.refreshing = false;
      }
      getList()
    }
    //获取列表
    const getList = () => {
      let param = {
        pageIndex: state.pageIndex,
        pageSize: state.pageSize
      }
      liH5Bulletin(param).then((res) => {
        state.loading = false
        if (res.result) {
          res.data.records.forEach(item => {
            item.beginQuotedDate = item.beginQuotedDate.replaceAll('-', '.');
            item.cutoffQuotedDate = item.cutoffQuotedDate.replaceAll('-', '.');
          })
          state.list.push(...res.data.records)
          if (state.pageIndex >= res.data.pages) {
            state.finished = true;
            return
          }
          state.pageIndex++
        }
      })
    }
    //下拉刷新
    const onRefresh = () => {
      //  初始化数据
      state.pageIndex = 1;
      state.list = [];
      state.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      state.loading = true;
      onLoad();
    }

    onMounted(() => {
      window.document.title = '招采公告';
      getList()
    })
    return {
      onRefresh,
      openNewPage,
      onLoad,
      openQuotedPrice,
      gotoDetails,
      ...toRefs(state),
    }
  }
}
</script>
<style lang="less" scoped>
div {
  box-sizing: border-box;
}

.wrap {
  background: #F8F8F9;
  font-size: 0.14rem;
  color: #666666;
  line-height: 0.24rem;
  letter-spacing: 0.5px;
  padding: 0 0 0.08rem 0;
  min-height: 100vh;
  height: auto;

  .routerToBox {
    position: fixed;
    top: 3.49rem;
    right: 0;
    width: 0.93rem;
    height: 0.37rem;
    background: #3967A2;
    border-radius: 0.19rem 0 0 0.19rem;
    opacity: 0.6;
    font-size: 0.15rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 0.37rem;
    text-align: center;
  }

  .listItem {
    margin-bottom: 0.16rem;
    width: 100%;
    min-height: 1.35rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.12rem 0.16rem;

    .stoneDiv {
      span {
        min-width: 0.8rem;
      }

      .stoneMain {
        //display: flex;
      }
    }

    &Top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      font-size: 0.15rem;
      font-weight: 600;
      color: #333333;
      line-height: 0.21rem;
      max-width: 70%;
      word-wrap: break-word;
      word-break: normal
    }

    .rightDiv {
      width: 0.92rem;
      height: 0.32rem;
      border-radius: 0.05rem;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.14rem;
      font-weight: 600;

      .icon {
        width: 0.05rem;
        height: 0.09rem;
        margin-left: 0.06rem;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .blue {
        background-image: url("../../assets/index/icon_rigth_blue.png");
      }

      .white {
        background-image: url("../../assets/index/icon_right_white.png");
      }
    }

    .offer {
      border: none;
      color: white;
      background: #3967A2;
      font-weight: 500;
    }

    .service {
      border: 1px solid #3967A2;
      color: #3967A2;
      background: #FFFFFF;

    }
  }

  .no-data {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: #666666;

    img {
      margin-top: 20vh;
      width: 66%;
      height: auto;
    }
  }
}
</style>
