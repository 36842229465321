import instance from "../../utils/request";
// TODO 短信登录-用户相关
export const liH5Bulletin = (params) => {
    return instance({
        url: "/h5/liH5Bulletin/select",
        method: 'post',
        data: params,
    })
}

export default {
    liH5Bulletin,
};
